import React from 'react';
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import { theme } from './styles/theme';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TopBar from './components/TopBar';
import HomeScreen from './pages/HomeScreen';
import ERPPage from './pages/ERPScreen';
import PDVScreen from './pages/PDVScreen';
import AboutScreen from './pages/AboutScreen';
import Footer from './components/Footer';

import { Analytics } from '@vercel/analytics/react';

function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Analytics />
            <div className="main">
                <Router>
                    <TopBar />
                    <Routes>
                        <Route path="/" element={<HomeScreen />} />
                        <Route path="/erp" element={<ERPPage />} />
                        <Route path="/pdv" element={<PDVScreen />} />
                        <Route path="/about" element={<AboutScreen />} />
                    </Routes>
                    <Footer />
                </Router>
            </div>
        </ThemeProvider>
    );
}

export default App;
